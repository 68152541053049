<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-22 15:35:03
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-07 10:56:40
-->
<template>
  <div class="list">
    <el-row>
      <el-col class="label" :span="12">外派公司</el-col>
      <el-col
        class="value text-ellipsis"
        :span="12"
        :title="salary.ecompanyName"
      >{{salary.ecompanyName}}</el-col>
    </el-row>
    <el-row>
      <el-col class="label" :span="12">所属公司</el-col>
      <el-col
        class="value text-ellipsis"
        :span="12"
        :title="salary.ocompanyName"
      >{{salary.ocompanyName}}</el-col>
    </el-row>
    <el-row>
      <el-col class="label" :span="12">收款名称</el-col>
      <el-col class="value" :span="12">{{salary.bankName}}</el-col>
    </el-row>
    <el-row>
      <el-col class="label" :span="12">收款账号</el-col>
      <el-col class="value" :span="12">{{salary.bankAccount}}</el-col>
    </el-row>
    <el-row>
      <el-col class="label" :span="12">收入月份</el-col>
      <el-col class="value" :span="12">{{salary.emolumentMonth}}</el-col>
    </el-row>
    <el-row>
      <el-col class="label" :span="12">发放日期</el-col>
      <el-col class="value" :span="12">{{salary.payDate}}</el-col>
    </el-row>
    <el-row>
      <el-col class="label" :span="12">含税收入</el-col>
      <el-col class="value" :span="12">{{salary.amountPayable}}</el-col>
    </el-row>
    <el-row>
      <el-col class="label" :span="12">扣缴税费</el-col>
      <el-col class="value" :span="12">
        {{salary.personalTaxAmount}}
        <!-- <template v-if="salary.taxAssume=='company_all'">0</template>
        <template v-else-if="salary.taxAssume=='personally_all'">{{salary.toAll}}</template>
        <template v-else>{{salary.personalTaxAmount}}</template>-->
      </el-col>
    </el-row>
    <el-row>
      <el-col class="label" :span="12">代付费用</el-col>
      <el-col class="value" :span="12">{{salary.payFeeAmount}}</el-col>
    </el-row>
    <el-row>
      <el-col class="label" :span="12">实发收入</el-col>
      <el-col class="value" :span="12">
        <el-link type="primary" style="font-weight:600">{{salary.amount}}</el-link>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="label" :span="12">发放备注</el-col>
      <el-col
        class="value text-ellipsis"
        :span="12"
        :title="salary.remark"
      >{{salary.remark?salary.remark:"无"}}</el-col>
    </el-row>

    <el-row>
      <el-col class="label" :span="24" style="text-align:right;margin-top:12px">
        <a href="javascript:;" @click="handleQuestion">对收入有疑问？</a>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: {
    salary: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    handleQuestion() {
      this.$emit("setQuestion");
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  font-size: 14px;
  line-height: 20px;
  /deep/ .el-row {
    margin-bottom: 12px;
  }

  .label {
    color: #999;
  }

  .value {
    color: #333;
    text-align: right;
  }
}
</style>