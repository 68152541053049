<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-22 15:35:03
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-08-27 14:06:31
-->
<template>
  <div class="list">
    <el-row>
      <el-col class="label" :span="12">状态</el-col>
      <el-col class="value main" :span="12">
        <template v-if="salary.payStatus  == 'failure'">支付失败</template>
        <template v-else-if="salary.payStatus  == 'stay_pay'">待付款</template>
        <template v-else-if="salary.payStatus  == 'paying'">支付中</template>
        <template v-else-if="salary.payStatus  == 'paid'">已付款</template>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="label" :span="12">发放渠道</el-col>
      <el-col class="value" :span="12">
        <template v-if="salary.paymentMethod  == 'tlt'">通联支付</template>
        <template v-else-if="salary.paymentMethod  == 'postal'">邮储支付</template>
        <template v-else-if="salary.paymentMethod  == 'offline'">线下支付</template>
        <template v-else-if="salary.paymentMethod  == 'spa'">平安支付</template>
        <template v-else-if="salary.paymentMethod  == 'ali'">支付宝支付</template>
        <template v-else-if="salary.paymentMethod  == 'cash'">主动提取</template>
		<template v-else-if="salary.paymentMethod  == 'czj'">好招支付</template>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="label" :span="12">发放时间</el-col>
      <el-col class="value" :span="12">{{salary.payDate}}</el-col>
    </el-row>
    <el-row>
      <el-col class="label" :span="12">收款方户</el-col>
      <el-col class="value" :span="12">{{salary.name}}</el-col>
    </el-row>
    <el-row>
      <el-col class="label" :span="12">收款方</el-col>
      <el-col class="value" :span="12">{{salary.bankName}}</el-col>
    </el-row>
    <el-row>
      <el-col class="label" :span="12">收款方账户</el-col>
      <el-col class="value" :span="12">{{salary.bankAccount}}</el-col>
    </el-row>
    <el-row>
      <el-col class="label" :span="12">发放金额</el-col>
      <el-col class="value" :span="12">{{salary.amount}}</el-col>
    </el-row>
    <el-row>
      <el-col class="label" :span="12">转账流水号</el-col>
      <el-col class="value" :span="12">{{salary.sn}}</el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: {
    salary: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  line-height: 20px;
  /deep/ .el-row {
    margin-bottom: 12px;
  }

  .value {
    text-align: right;
    &.main {
      color: #ff3838;
      font-size: 18px;
    }
  }
}
</style>