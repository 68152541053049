<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-26 17:25:32
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-02 10:39:10
-->
<template>
  <el-dialog
    title="请选择问题类型并留言"
    width="390px"
    :visible.sync="isShow"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-radio-group v-model="type_text" class="radioList">
      <el-radio-button
        v-for="(item,index) in feedbackTypeList"
        :key="'feedbackTypeList'+index"
        :label="item.value"
        @click.native="handleClick(item)"
      ></el-radio-button>
    </el-radio-group>
    <el-input
      type="textarea"
      :autosize="{ minRows: 4, maxRows: 6}"
      :placeholder="placeholder"
      v-model="content"
    ></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import api from "@/api/list";
export default {
  props: {
    emolument: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isShowQuestion: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isShowQuestion: function (val) {
      this.isShow = val;
    },
  },
  data() {
    return {
      placeholder: "请选择问题类型并留言反馈",
      feedbackTypeList: [],
      type: "",
      type_text: "",
      content: "",
      isShow: this.isShowQuestion,
    };
  },
  mounted() {
    api.getDataDictList({ group: "contentFeedbackType" }).then((res) => {
      if (res.status == "200") {
        this.feedbackTypeList = res.data;
        this.feedbackTypeList.splice(
          this.feedbackTypeList.findIndex(
            (item) => item.code === "bjnews_feedback"
          ),
          1
        );
        if (res.data.length > 0) {
          this.placeholder = `请填写${res.data[0].value}问题反馈...`;
          this.type = res.data[0].id;
          this.type_text = res.data[0].value;
        }
      }
    });
  },

  methods: {
    handleSubmit() {
      if (this.content.trim() == "") {
        this.$message({
          showClose: true,
          message: this.placeholder,
          type: "warning",
        });
        return false;
      }

      let parm = {
        type: this.type,
        content: this.content,
        username: this.emolument.username,
        employeeName: this.emolument.name,
        mobile: this.emolument.mobile,
        companyName: this.emolument.ecompanyName,
      };
      api.contentFeedbackPostAction(parm, { method: "post" }).then((res) => {
        if (res.status == "200") {
          this.$message({
            showClose: true,
            message: "反馈成功",
            type: "success",
          });
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: "error",
          });
        }
        this.handleClose();
      });
    },
    handleClick(item) {
      this.type = item.id;
      this.type_text = item.value;
      this.placeholder = `请填写${item.value}问题反馈...`;
    },
    handleClose() {
      this.content = "";
      this.isShow = false;
      this.$emit("setVisible");
    },
  },
};
</script>
<style lang="less" scoped>
.radioList {
  margin-bottom: 10px;
}
</style>