<template>
  <div class="page_income">
    <section>
      <header>
        <el-form ref="searchForm" :model="searchForm" label-width="80px" size="mini">
          <el-row>
            <el-col :span="8">
              <el-form-item label="收入月份">
                <el-date-picker v-model="searchForm.emolumentMonth" type="month" placeholder="请选择薪资月份" value-format="yyyy-MM" @change="handleSearch"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="发放月份">
                <el-date-picker v-model="searchForm.createDate" type="month" placeholder="请选择发放月份" value-format="yyyy-MM" @change="handleSearch"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="外派公司">
                <el-select v-model="searchForm.ecompanyId" placeholder="请选择外派公司" @change="handleSearch" clearable>
                  <el-option v-for="(item,index) in ecompanyList" :key="'ecompany'+index" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </header>
      <template v-if="list.length">
        <el-table :data="list" stripe style="width: 100%">
          <el-table-column prop="ecompanyName" label="外派公司"></el-table-column>
          <el-table-column prop="emolumentMonth" label="收入月份"></el-table-column>
          <el-table-column prop="payDate" label="发放时间"></el-table-column>
          <el-table-column prop="amountPayable" label="发放金额"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link type="primary" @click="detailData = scope.row.isShowDetail = true">详情</el-link>
              <el-link type="primary" @click="detailData = scope.row.isShowReceipt = true">回单</el-link>
              <el-link type="primary" v-if="scope.row.voucherUrl" class="image_preview" @click="handlePDFPreview(scope.row.voucherUrl)"> 查看凭证 <el-image v-if="!scope.row.voucherUrl.toUpperCase().endsWith('.PDF')" :src="scope.row.voucherUrl" :preview-src-list="scope.row.voucherUrlList"></el-image>
              </el-link>
              <!--<el-link type="primary" v-else @click="detailData = scope.row.isUploadShow = true">上传凭证</el-link>-->
            </template>
          </el-table-column>
        </el-table>
        <pagination :listLoading="listLoading" @getList="getList" @setLimit="searchForm.size = $event" @setPage="searchForm.current = $event" :currentPage="searchForm.current" :pageSize="searchForm.size" :total="searchForm.total"></pagination>
      </template>
      <template v-else>
        <empty text="暂无历史收入"></empty>
      </template>
    </section>
    <el-dialog title="收入明细" :visible.sync="isShowDetail" width="462px" class="pretty_bg">
      <detail :salary="detailData" @setQuestion="isShowQuestion = true" />
    </el-dialog>
    <el-dialog :visible.sync="isShowReceipt" width="400px">
      <receipt :salary="detailData" />
    </el-dialog>
    <el-dialog title="上传凭证" :visible.sync="isUploadShow" width="400px">
      <div style="text-align:center">
        <upload-file text="点击上传凭证" subUrl="employee-voucher" :limit="1" :fileList="[]" @setFile="handleUploadFile($event)"></upload-file>
        <el-button type="primary" style="width:150px;margin:10px" @click="handleSubmit">提交</el-button>
      </div>
    </el-dialog>
    <question :emolument="detailData" :isShowQuestion="isShowQuestion" @setVisible="isShowQuestion = false" />
  </div>
</template>

<script>
import empty from "@/components/empty/index.vue";
import pagination from "@/components/pagination/index.vue";
import api from "@/api/list";
import detail from "./details.vue";
import receipt from "./receipt.vue";
import question from "./question.vue";
import uploadFile from "@/components/upload/index.vue";
import moment from "moment";
export default {
  name: "salaryList",
  components: {
    pagination,
    empty,
    detail,
    receipt,
    question,
    uploadFile,
  },
  data() {
    return {
      isShowQuestion: false,
      ecompanyList: [],
      emolument: {
        id: null,
        amount: 0,
        createDate: "暂未发放",
        amountPayable: 0,
        personalTaxAmount: 0,
      },
      listLoading: false,
      detailData: null,
      isShowDetail: false,
      isShowReceipt: false,
      isUploadShow: false,
      uploadFile: "",
      searchForm: {
        ecompanyId: null,
        emolumentMonth: null,
        createDate: null,
        current: 1,
        size: 10,
        total: null,
      },
      list: [],
      uploadUrl: "",
    };
  },
  computed: {},
  mounted() {
    //获取任务类型
    api.getEcompany().then((res) => {
      this.ecompanyList = res.data;
    });

    //获取历史薪资列表
    this.getList();

    api.getRecentlySalary().then((res) => {
      if (res.data) {
        this.emolument = res.data;
        if (this.emolument !== null) {
          this.emolument.amountPayable = this.emolument.amountPayable.toFixed(
            2
          );
          this.emolument.personalTaxAmount = this.emolument.personalTaxAmount.toFixed(
            2
          );
          this.emolument.amount = this.emolument.amount.toFixed(2);
        }
      }
    });
  },
  methods: {
    handleShowQuestion() {
      if (this.emolument.id != null) {
        this.isShowQuestion = true;
        this.detailData = this.emolument;
      }
    },
    handleUploadFile(file) {
      this.uploadUrl = file[0].replace(process.env.VUE_APP_FILE_PATH, "");
    },
    handleSubmit() {
      api
        .setEmployeeSetSalaryVoucher(
          { url: this.uploadUrl, emolumentId: this.detailData.id },
          { method: "patch" }
        )
        .then(() => {
          this.$message({
            message: "凭证上传成功",
            type: "success",
          });
          this.isUploadShow = false;
          this.getList();
        });
    },
    handleRecentlyDetail(id) {
      if (this.emolument.id != null) {
        api.getRecentlyDetail({}, { subUrl: id }).then((res) => {
          this.isShowDetail = true;
          this.detailData = res.data;
        });
      }
    },
    getList() {
      //获取历史薪资列表
      api.getSalaryList(this.searchForm).then((res) => {
        this.list = res.data.records.map((v) => {
          v.amountPayable = "￥" + v.amountPayable;
          if (v.voucherUrl) {
            v.voucherUrl = process.env.VUE_APP_FILE_PATH + v.voucherUrl;
            let arr = new Array();
            arr[0] = v.voucherUrl;
            v.voucherUrlList = arr;
          }
          return v;
        });

        this.searchForm.total = res.data.total;
      });
    },
    handleSearch() {
      this.searchForm.current = 1;
      this.getList();
    },
    handlePDFPreview(url) {
      if (url.toUpperCase().endsWith(".PDF")) {
        let routeData = this.$router.resolve({
          path: "/pdfPreview",
          query: {
            fileURL: url,
          },
        });
        window.open(routeData.href, "_blank");
      }
    },
    formatTime(date) {
      return moment(date).format("YYYY年MM月DD日");
    },
    handleWithdrawals(id) {
      this.$router.push({ name: "withdrawals" });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="less" scoped>
.page_income {
  padding-top: 0.5em;
  background: #fff;
  section {
    margin-bottom: 12px;
    border-radius: 6px;
    .image_preview {
      position: relative;
      /deep/ .el-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        & > img {
          opacity: 0;
        }
      }
    }
    p {
      margin: 0;
    }

    header {
      font-size: 18px;
      line-height: 50px;
      padding-left: 30px;
      font-weight: normal;
      /deep/ .el-form {
        margin: 10px 0;
        width: 80%;
        .el-form-item--mini.el-form-item {
          margin-bottom: 0;
        }
      }
    }

    /deep/ .el-table {
      min-height: 300px;
      .cell {
        text-align: center;
      }

      td,
      th {
        padding: 4px 0;
      }

      thead th {
        background: #8f9aee;
        color: #fff;
      }

      &.el-table--striped .el-table__body tr.el-table__row--striped td {
        background: #eff0f6;
      }
    }
  }

  .pretty_bg {
    /deep/.el-dialog {
      padding: 20px 40px;
      height: 542px;
      background: url(../../../assets/salary_bg.png) center center no-repeat;
      background-size: contain;
      border: none;
      box-shadow: none;
      .el-dialog__header {
        padding-top: 20px;
        .el-dialog__headerbtn {
          top: 40px;
          right: 56px;

          .el-dialog__close {
            color: #333;
          }
        }
      }
      .el-dialog__body {
        padding: 24px 20px;
      }
    }
  }
}

.file_box {
  display: block;
  width: 100%;
  height: 722px;
}

/*
 * 20201124添加选项卡样式
 */
.salary_tabs {
  background: white;
}
.el-link {
  margin: 0 10px;
}
div /deep/ .el-tabs__item {
  font-size: 16px;
  font-weight: bold;
  height: 60px;
  line-height: 60px;
}
</style>